@keyframes bounce
  0%
    transform: translateY(-4px)

  25%
    transform: translateY(0)

  50%
    transform: translateY(-2px)

  75%
    transform: translateY(0)

  90%
    transform: translateY(-1px)

  100%
    transform:translateY(0)

@keyframes rotating
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

@keyframes pop
  0%
    transform: scale(0)
  50%
    transform: scale(1.6)
  100%
    transform: scale(1)

@keyframes slideUp
  from
    transform: translateY(100%)
  to
    transform: translateY(0)


@keyframes grab
  0%
    transform: translateY(100%)
    z-index: 1
  80%
    transform: translateY(-50%)
    z-index: 1
  81%
    transform: translateY(-50%)
    z-index: 5
  100%
    transform: translateY(0)
    z-index: 5

@keyframes lower
  from
    transform: translateY(0)
  to
    transform: translateY(10%)

@keyframes fadeIn
   from
    opacity: 0

   to
    opacity: 1

@keyframes fadeInUp
  0%
    opacity: 0
    transform: translateY(35px)

  100%
    opacity: 1
    transform: translateY(0)

@keyframes slideFade
  from
    opacity: 0
    transform: translateX(50px)

  to
    opacity: 1
    transform: translateX(0)

@keyframes spinOpen
  100%
    transform: rotate(45deg)

@keyframes spinClose
  0%
    transform: rotate(45deg)
  100%
    transform: rotate(0deg)
