@import '~@/styles/variables'
@import '~@/styles/mixins'

.spinner
  width: 42px
  display: block
  margin: 90px auto
  animation: rotating 0.8s infinite linear

dump_media_cache()
