@import '~@/styles/variables'
@import '~@/styles/mixins'

section 
  padding: 36px 20px

img 
  max-width: 160px
  margin-bottom: 12px
  opacity: 0.7

dump_media_cache()