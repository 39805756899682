#mapid {
  height: 330px;
  position: relative;
  margin: 0;
}
@media (min-width: 470px) {
  #mapid {
    height: 400px;
  }
}
@media (min-width: 768px) {
  #mapid {
    margin-top: 0rem;
    height: 450px;
  }
}
@media (min-width: 900px) {
  #mapid {
    height: 605px;
    width: 605px;
    flex: 0 0 auto;
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translateX(45%) translateY(50%);
    padding-top: 0;
  }
}
@media (min-width: 900px) {
  .vue2leaflet-map {
    border-radius: 50% 50%;
    transform: scale(0.6);
  }
}
@media (min-width: 1000px) {
  .vue2leaflet-map {
    transform: scale(0.7);
  }
}
@media (min-width: 1100px) {
  .vue2leaflet-map {
    transform: scale(0.8);
  }
}
.globe-shading {
  top: -1px;
  left: -1px;
  position: absolute;
  z-index: 1000;
  height: 101%;
  width: 100%;
  display: none;
}
@media (min-width: 900px) {
  .globe-shading {
    display: block;
    transform: scale(0.6);
  }
}
@media (min-width: 1000px) {
  .globe-shading {
    transform: scale(0.7);
  }
}
@media (min-width: 1100px) {
  .globe-shading {
    transform: scale(0.8);
  }
}
img.leaflet-tile.leaflet-tile-loaded {
  height: 258px !important;
  width: 258px !important;
}
.leaflet-popup.leaflet-zoom-animated {
  margin-bottom: 72px;
  margin-left: 5px;
}
#mapid.connected .leaflet-popup.leaflet-zoom-animated {
  margin-left: 10px;
}
.leaflet-popup-content.leaflet-popup-content {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  color: #555;
  margin: 13px 0;
  width: auto;
  min-width: 150px;
}
.leaflet-popup-content.leaflet-popup-content h3 {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 0;
  padding: 0 0.5rem;
  width: auto;
}
@media (min-width: 470px) {
  .leaflet-popup-content.leaflet-popup-content h3 {
    font-size: 1.4rem;
  }
}
.leaflet-popup-content.leaflet-popup-content h3:nth-of-type(2) {
  margin-top: 0;
  margin-bottom: 0;
}
.leaflet-popup-content.leaflet-popup-content p {
  font-size: 0.8rem;
  font-weight: normal;
  margin: 0;
  padding: 0 0.4rem;
}
@media (min-width: 470px) {
  .leaflet-popup-content.leaflet-popup-content p {
    font-size: 1.1rem;
  }
}
.leaflet-control-attribution.leaflet-control a {
  display: none;
}
/*# sourceMappingURL=src/components/IPTracker/IPTracker-map.css.map */