.standard-hero
  text-align: center
  padding-top: 35px

  +media('sm')
    padding: 20px

  h1
    max-width: 86%
    margin-left: auto
    margin-right: auto

    +media('xs')
      max-width: 100%

  .tagline
    max-width: 700px
    margin: 0 auto 40px
