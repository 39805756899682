btn(bg, color, hover-bg, hover-color)
  background-color: bg
  border: 1px solid bg
  color: color

  &:hover
    color: hover-color
    background-color: hover-bg
    border: 1px solid hover-bg

  &.hollow
    background-color: transparent
    color: bg

    &:hover
      background-color: transparent
      color: hover-bg

body
  /* Button */
  button, .btn
    btn($btn-neutral, $white, darken($btn-neutral, 5%), $white)
    font-size: $p-em
    font-family: Montserrat, Helvetica, sans-serif
    border-radius: 30px
    display: inline-block
    padding: 10px 22px
    text-align: center
    font-weight: 500

    &.plain
      background: transparent
      border: 0
      padding: 0
      margin: 0
      display: inline
      text-align: left

      &.link
        color: $link-regular
        text-decoration: underline

      &.dark
        color: $mid-dark-grey

      &:hover, &:focus
        background: transparent
        border: 0
        text-decoration: underline

    &:hover
      cursor: pointer
      text-decoration: none

    &:active, &:focus
      cursor: pointer

    &:active
      box-shadow: none

    &[disabled]
      opacity: 0.8
      color: $mid-dark-grey
      background-color: $btn-disabled
      border-color: $btn-disabled
      cursor: not-allowed

    &.mid-grey
      btn($mid-grey, $white, darken($mid-grey, 10%), $white)

    &.dark-grey
      btn($dark-grey, $white, darken($dark-grey, 10%), $white)

    &.grey
      btn($grey, $dark-grey, darken(#EBEAE8, 5%), $dark-grey)

    &.rb-blue
      btn($rb-dark-blue, $white, darken($rb-dark-blue, 8%), $white)

    &.green
      btn($green, $white, darken($green, 8%), $white)

    &.red
      btn($red, $white, darken($red, 5%), $white)

  button.link
    border: 0
    background-color: transparent
    padding: 0
    margin: 0
    display: inline
    font-weight: 400
    font-size: $p-em
    width: auto
    color: $link-regular

    &.center
      text-align: center
      margin-left: auto
      margin-right: auto
      display: block

    &.right
      margin-left: auto
      margin-right: 0
      display: block

    &:hover
      background-color: transparent
      text-decoration: underline


  .btn-lg
    border-radius: 40px
    padding: 18px 36px
    line-height: 1.33em
    font-size: $h4-em

    +media('sm')
      padding: 13px 28px
      line-height: 1.33em
      font-size: $h5-em

  .btn-full
    width: 100%

  .submit-btn
    &:focus
      outline: none
      box-shadow: 0px 0px 10px $focus-outline

$media (max-width: 900px)
  body
    button, .btn
      font-size: $sm-txt

    .btn-lg
      font-size: $p-em

$media (max-width: 480px)
  .btn-lg
    padding: 13px 20px
    font-size: $h6-em
