section.header {
  position: relative;
}
section.header .screen {
  position: absolute;
  z-index: 1100;
  width: 100%;
  height: 100%;
}
section.header .header-container {
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
}
@media (min-width: 900px) {
  section.header .header-container {
    display: flex;
    justify-content: space-between;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
section.header .map-outer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 45%;
}
section.header .map-container {
  padding-top: 0px;
  position: relative;
}
@media (min-width: 900px) {
  section.header .map-container {
    height: 400px;
    width: 400px;
    margin: 0 auto;
  }
}
section.header.header {
  padding: 0 0 4rem 0;
  margin: 0 auto;
}
@media (min-width: 768px) {
  section.header.header {
    padding: 1.45rem 0 4rem 0;
  }
}
@media (min-width: 900px) {
  section.header.header {
    padding-top: 5.45rem;
    padding-bottom: 0;
  }
}
section.header.header h1 {
  position: relative;
  right: 3px;
  font-size: 2.716em;
  margin-top: 1rem;
}
@media (min-width: 470px) {
  section.header.header h1 {
    font-size: 3.21em;
  }
}
@media (min-width: 900px) {
  section.header.header h1 {
    margin-top: 0;
  }
}
section.header.header .left-side-copy {
  order: -1;
  width: 95%;
  margin: 0 auto;
}
@media (min-width: 900px) {
  section.header.header .left-side-copy {
    margin: 0;
    padding-left: 3.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 31.175rem;
    flex: 0 0 55%;
  }
}
@media (min-width: 960px) {
  section.header.header .left-side-copy {
    max-width: 32.675rem;
  }
}
section.header.header .ip-address,
section.header.header .location {
  font-weight: 500;
}
section.header.header .ip-address-result h2 {
  display: inline-block;
  font-size: 1.6rem;
  position: relative;
  width: 100%;
}
@media (min-width: 360px) {
  section.header.header .ip-address-result h2 {
    font-size: 2rem;
  }
}
@media (min-width: 570px) {
  section.header.header .ip-address-result h2 {
    font-size: 2.4rem;
  }
}
section.header.header .ip-address-result h2 .security-icon {
  display: inline-block;
  width: 0.6em;
  left: 0;
  margin-right: 5px;
  position: relative;
}
section.header.header .ip-address-result h2.protected .security-icon {
  content: url("./images/lock.svg");
}
section.header.header .ip-address-result h2.ghost .security-icon {
  content: url("./images/ghostbear.svg");
}
section.header.header .ip-address-result h2.unprotected .security-icon {
  content: url("./images/warning.svg");
  width: 0.9em;
}
section.header.header .ip-address-result.small h2 {
  font-size: 0.94rem;
  width: 21.375rem;
}
@media (min-width: 360px) {
  section.header.header .ip-address-result.small h2 {
    font-size: 4.2vw;
    width: 100%;
  }
}
@media (min-width: 900px) {
  section.header.header .ip-address-result.small h2 {
    font-size: 2.1vw;
  }
}
@media (min-width: 1070px) {
  section.header.header .ip-address-result.small h2 {
    font-size: 1.375rem;
  }
}
section.header.header .protected-result {
  font-size: 1.125em;
  line-height: 1.5em;
  margin-bottom: 1.75rem;
}
section.header.header .unprotected {
  color: rgba(225,6,26,0.8);
}
section.header.header .protected {
  color: #83b84d;
}
section.header.header .ghost {
  text-shadow: 0 0 4px #79b5c4, 0 0 1.5em #4d4d4d;
  color: #496574;
}
section.header.header .location {
  margin-bottom: 0;
}
section.header.header .location-result {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0;
}
@media (min-width: 470px) {
  section.header.header .location-result {
    font-size: 2.25rem;
  }
}
section.header.header .location-result.country-only {
  margin-bottom: 3rem;
}
section.header.header .service-provider {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 4rem;
}
@media (min-width: 470px) {
  section.header.header .service-provider {
    font-size: 1.25rem;
  }
}
section.header.header .stay-private {
  margin: 0 auto;
  display: block;
  padding: 13px 13px;
  font-size: 1rem;
  max-width: 350px;
}
@media (min-width: 326px) {
  section.header.header .stay-private {
    font-size: 1.125rem;
  }
}
@media (min-width: 900px) {
  section.header.header .stay-private {
    max-width: 350px;
    margin: 0;
  }
}
/*# sourceMappingURL=src/components/IPTracker/IPTracker.css.map */