@import '~@/styles/variables'
@import '~@/styles/mixins'

  section.header
    position: relative

    .screen //prevents user from selecting map
      position: absolute
      z-index: 1100
      width: 100%
      height: 100%

    .header-container
      position: relative
      max-width: 1100px
      margin: 0 auto
      overflow: hidden

      @media(min-width: 900px)
        display: flex
        justify-content: space-between
        padding-top: 5rem
        padding-bottom: 5rem

    .map-outer-container
      display: flex
      flex-direction: column
      justify-content: center
      flex: 0 0 45%

    .map-container
      padding-top: 0px
      position: relative
      
      @media(min-width: 900px)
        height: 400px
        width: 400px
        margin: 0 auto

    &.header
      padding: 0 0 4rem 0
      margin: 0 auto

      @media(min-width: 768px)
          padding: 1.45rem 0 4rem 0

      @media(min-width: 900px)
          padding-top: 5.45rem
          padding-bottom: 0

      h1
        position: relative
        right: 3px
        font-size: 2.716em
        margin-top: 1rem

        @media(min-width: 470px)
          font-size: 3.21em

        @media(min-width: 900px)
          margin-top: 0

      .left-side-copy
        order: -1
        width: 95%
        margin: 0 auto

        @media(min-width: 900px)
            margin: 0
            padding-left: 3.75rem
            display: flex
            flex-direction: column
            justify-content: center
            max-width: 31.175rem
            flex: 0 0 55%

        @media(min-width: 960px)
          max-width: 32.675rem

      .ip-address,
      .location
        font-weight: 500

      .ip-address-result

          h2
            display: inline-block
            font-size: 1.6rem
            position: relative
            width: 100%

            @media(min-width: 360px)
              font-size: 2rem
            
            @media(min-width: 570px)
              font-size: 2.4rem

            & .security-icon  
                display: inline-block
                width: 0.6em
                left: 0
                margin-right: 5px
                position: relative

            &.protected .security-icon
                content: url('./images/lock.svg')

            &.ghost .security-icon
                content: url('./images/ghostbear.svg')

            &.unprotected .security-icon
                content: url('./images/warning.svg')
                width: 0.9em

          &.small

            h2
              font-size: 0.94rem
              width: 21.375rem

              @media(min-width: 360px)
                font-size: 4.2vw
                width: 100%

              @media(min-width: 900px)
                font-size: 2.1vw

              @media(min-width: 1070px)
                font-size: 1.375rem

      .protected-result
        font-size: 1.125em
        line-height: 1.5em
        margin-bottom: 1.75rem

      .unprotected
        color: rgba(225,6,26,0.8)

      .protected
        color: $green

      .ghost
        text-shadow: 0 0 4px $ghostly, 0 0 1.5em $charcoal
        color: $ghosdark

      .location
        margin-bottom: 0

      .location-result
        font-size: 1.8rem
        font-weight: 600
        margin-bottom: 0

        @media(min-width: 470px)
          font-size: 2.25rem

        &.country-only
          margin-bottom: 3rem

      .service-provider
        font-size: 1rem
        font-weight: 600
        margin-bottom: 4rem

        @media(min-width: 470px)
          font-size: 1.25rem

      .stay-private
        margin: 0 auto
        display: block
        padding: 13px 13px
        font-size: 1rem
        max-width: 350px

        @media(min-width: 326px)
          font-size: 1.125rem

        @media(min-width: 900px)
          max-width: 350px
          margin: 0