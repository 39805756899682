/*=============
GENERAL STYLES
==============*/
.hidden {
  opacity: 0;
}
.animation-spinner {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11000;
}
@media (min-width: 537px) {
  .container.split {
    display: flex;
  }
}
.container.split > * {
  flex-basis: 100%;
}
/*=============
HEADER STYLES
==============*/
.error {
  background: #fff;
  padding-top: 3.25rem;
  padding-bottom: 3rem;
}
.error h1 span {
  display: block;
  font-size: 0.744em;
}
@media (min-width: 768px) {
  .error {
    padding-top: 11.25rem;
    padding-bottom: 5.5rem;
  }
}
.error-image,
.error-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.error-message {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 2.5rem;
}
@media (min-width: 537px) {
  .error-message {
    width: 50%;
    text-align: left;
    margin-bottom: 0;
  }
}
.error-image img {
  max-width: 280px;
  margin: 0 auto;
}
@media (min-width: 471px) {
  .error-image img {
    max-width: 320px;
  }
}
@media (min-width: 537px) {
  .error-image img {
    max-width: 465px;
  }
}
/*=============
SECTION STYLES
==============*/
section.install,
section.faq {
  padding-top: 0;
  padding-bottom: 55px;
}
section.faq {
  background: #fff;
}
div.install,
div.faq {
  padding: 4.375rem 1.6rem 0;
  max-width: 500px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  div.install,
  div.faq {
    max-width: 1020px;
  }
}
div.install h2,
div.faq h2 {
  text-align: center;
  margin-bottom: 2.8rem;
}
.top-row,
.bottom-row {
  margin-left: 0;
}
@media (min-width: 768px) {
  .top-row,
  .bottom-row {
    display: flex;
  }
  .top-row li + li,
  .bottom-row li + li {
    margin-left: 1rem;
  }
}
ul.split {
  padding-left: 0;
  list-style: none;
  margin-bottom: 3.2rem;
}
@media (min-width: 768px) {
  ul.split {
    display: flex;
  }
}
.faq ul.split {
  flex-direction: column;
}
li {
  margin: 0 auto;
  display: flex;
  margin-bottom: 2rem;
  flex-basis: 100%;
}
@media (min-width: 768px) {
  .install li {
    flex-direction: column;
    align-items: center;
  }
  .install li + li {
    margin-left: 2rem;
  }
  .install li h4,
  .install li p {
    text-align: center;
  }
  .install li h4 {
    margin-bottom: 1.3rem;
  }
}
@media (min-width: 768px) {
  .faq li {
    min-height: 135px;
  }
}
li img {
  width: 4.7rem;
  height: 4.7rem;
  margin-right: 1.875rem;
}
@media (min-width: 768px) {
  .install li img {
    width: 9.4rem;
    height: 9.4rem;
    margin-right: 0;
    margin-bottom: 0.7rem;
  }
}
a.btn.btn-lg {
  margin: 0 auto;
  display: block;
  max-width: 250px;
}
/*# sourceMappingURL=src/views/WhatsMyIP/WhatsMyIP.css.map */