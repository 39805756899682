@import '~@/styles/variables'
@import '~@/styles/mixins'

/*=============
GENERAL STYLES
==============*/

.hidden 
  opacity: 0

.animation-spinner
  position: absolute
  top: 20%
  left: 50%
  transform: translateX(-50%)
  z-index: 11000

.container.split 
  
  @media(min-width: 537px)
    display:flex

  & > *
    flex-basis: 100%

/*=============
HEADER STYLES
==============*/

.error
  background: $bg-regular
  padding-top: 3.25rem
  padding-bottom: 3rem

  h1
    span 
      display: block
      font-size: 0.744em

  @media(min-width: 768px)
    padding-top: 11.25rem
    padding-bottom: 5.5rem

.error-image,
.error-message
  display: flex
  flex-direction: column
  justify-content: center

.error-message
  width: 80%
  margin: 0 auto
  margin-bottom: 2.5rem
      
  @media(min-width: 537px)
    width:50%
    text-align: left
    margin-bottom: 0

.error-image
  img
    max-width: 280px
    margin: 0 auto

    @media(min-width: 471px)
      max-width: 320px

    @media(min-width: 537px)
      max-width: 465px

/*=============
SECTION STYLES
==============*/

section.install,
section.faq
  padding-top: 0
  padding-bottom: 55px;

section.faq
  background: $white

div.install,
div.faq
  padding: 4.375rem 1.6rem 0
  max-width: 500px
  margin: 0 auto

  @media(min-width: 768px)
      max-width: 1020px
  
  h2
    text-align: center
    margin-bottom: 2.8rem

.top-row,
.bottom-row

  margin-left: 0

  @media(min-width: 768px)
    display: flex

    li + li
      margin-left: 1rem

ul.split
  padding-left: 0
  list-style: none
  margin-bottom: 3.2rem

  @media(min-width: 768px)
    display: flex

  .faq &
    flex-direction: column

li
  margin: 0 auto
  display: flex
  margin-bottom: 2rem
  flex-basis: 100%

  .install &

    @media(min-width: 768px)
        flex-direction: column
        align-items: center

        & + li
          margin-left: 2rem

        h4, p
          text-align: center

        h4
          margin-bottom: 1.3rem

  .faq &

    @media(min-width: 768px)
      min-height: 135px

  img
    width: 4.7rem
    height: 4.7rem
    margin-right: 1.875rem

    .install &

      @media(min-width: 768px)
        width: 9.4rem
        height: 9.4rem
        margin-right: 0
        margin-bottom: 0.7rem

a.btn.btn-lg
  margin: 0 auto
  display: block
  max-width: 250px