@font-face
  font-family: Montserrat
  font-display: swap
  font-style: normal
  font-weight: 400
  src: url('./fonts/Montserrat-Regular.ttf')

@font-face
  font-family: Montserrat
  font-display: swap
  font-style: normal
  font-weight: 500
  src: url('./fonts/Montserrat-Medium.ttf')

@font-face
  font-family: Montserrat
  font-display: swap
  font-style: normal
  font-weight: 600
  src: url('./fonts/Montserrat-SemiBold.ttf')

@font-face
  font-family: Montserrat
  font-display: swap
  font-style: normal
  font-weight: 700
  src: url('./fonts/Montserrat-Bold.ttf')

@font-face
  font-family: 'social'
  src: url('./fonts/fontello.eot')
  src: url('./fonts/fontello.eot#iefix') format('embedded-opentype'),
       url('./fonts/fontello.woff') format('woff'),
       url('./fonts/fontello.ttf') format('truetype'),
       url('./fonts/fontello.svg#fontello') format('svg')
  font-weight: normal
  font-style: normal

i[class^="icon-"], i[class*=" icon-"]
  background-size: contain
  background-repeat: no-repeat
  background-position: center center
  position: relative
  overflow: hidden
  display: inline-block
  width: 22px
  height: 22px
  // screen readers
  span
    position: absolute
    right: 150%